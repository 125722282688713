import axios from "axios"
import {
  formatCurrency as _formatCurrency,
  coerceStringToJsNumber,
  formatDate,
  getEntityClientRoutes,
  getEntityServerRoute,
  isValidIban,
  objectToSearchParam,
  PROPS_,
  sleep,
  toQueryString,
} from "basikon-common-utils"
import { addHook } from "dompurify"
import _getBrowserFingerprint from "get-browser-fingerprint"
import hljs from "highlight.js"
import Leaflet from "leaflet"
import cloneDeep from "lodash.clonedeep"
import get from "lodash.get"
import marked from "marked"
import mermaid from "mermaid"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import FormContent from "@/_components/FormContent"
import PanelInner from "@/_components/PanelInner"

import { getAuthorizationToken } from "@/_services/axios"
import { getUriAndCacheResponse, resetCache } from "@/_services/cache"
import * as consoleService from "@/_services/console"
import { getLabel, resetLists } from "@/_services/lists"
import * as localizationService from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { axiosGetData } from "@/_services/offlineService"
import { validateSpanishId } from "@/_services/personUtils"
import { resetScripts } from "@/_services/scripts"
import { applyUserConfigurationStyles, removeUserConfigurationStyles } from "@/_services/theming"
import {
  canCheckTenantConfig,
  getConfigAtPath,
  getLoadingDate,
  getOptions,
  getPageConfig,
  getTenant,
  loadUserConfiguration,
  unloadUserConfiguration,
} from "@/_services/userConfiguration"

export const defaultMapCoordinates = [48.864716, 2.349014] /* Paris */
export const htmlTag = document.getElementsByTagName("html")[0]
export const bodyTag = document.getElementsByTagName("body")[0]
export const QUERY_PARAMS = ["jwt", "page", "token", "filter", "editMode", "parentUrl", "selectedIds", "noDecoration"]
export const appModes = { FLOW: "flow" }
export const entityModelBtnId = "entity-model-btn"
export const virtualKeyboardToggleBtnId = "virtual-keyboard-toggle-btn"
export const sideColumnToggleId = "side-column-toggle"
export const cardsCollapseToggleId = "collapse-expand-btn"
export const uploadWithScanInputId = "upload-with-scan-input"
export const globalSearchBtnId = "global-search-btn"
export const globalSearchInputId = "global-search-input"
export const layoutPageClassName = "pages-layout"
export const pseudoSelectOptionClassName = "pseudo-select-option"
export const DEFAULT_DEBOUNCE = 250 // 250ms / 0.25s

let browserFingerPrint = ""
export async function getBrowserFingerprint() {
  browserFingerPrint = browserFingerPrint || _getBrowserFingerprint().toString()
  return browserFingerPrint
}

export const localStorageKeys = {
  APP_VERSION: "appVersion",
  AUTOVISTA: {
    DATA_TO_FILL: "Autovista.dataToFill",
  },
  FIREBASE_TOKEN_PREFIX: "firebaseToken-",
  HEADER_AUTH: "headerAuthorization",
  LOCALE: "locale",
  LOCALES_COMPARISON_PREFIX: "localesComparison",
  MFA_REMEMBER_THIS_DEVICE: "mfaRememberThisDevice",
  HEADER_X_USER_CONTEXT: "headerXUserContext",
  HEADER_CONTEXT_TOKEN: "headerXContextToken",
  USER_CURRENT_CONTEXT_INDEX: "userCurrentContextIndex",
  OFFLINE_MODE: {
    LIST_PREFIX: "offlineList-",
    STORAGE_NAME: "offlineStorage",
    STORAGE_ERRORS: "offlineStorageErrors",
    STORAGE_VERSION: "offlineStorageVersion",
  },
  OIDC: {
    ID: "oidcId",
    STATE: "oidcState",
    NONCE: "oidcNonce",
    CODE_VERIFIER: "oidcCodeVerifier",
    ORIGINAL_URL: "oidcOriginalUrl",
    LOGOUT_URL: "oidcLogoutUrl",
    REDIRECT_URI: "oidcRedirectUri",
    AUTO: "oidcAuto",
    ID_TOKEN: "oidcIdToken",
  },
  UI_STATE: {
    ROOT: "uiState",
    THEMING: {
      NAV_CONTROLS_DISPLAY_MODE: "theming.navControlsDisplayMode",
    },
    SIDE_VIEW: {
      MODE: "sideView.mode",
    },
    CARD: {
      SHOW_SIDE_COLUMN: "Card.showSideColumn",
    },
    PROJECT_TIME_ENTRIES: {
      IS_CALENDAR: "ProjectTimeEntries.isCalendar",
    },
    BUDGET_PAGE: {
      SHOW_DIFF_COLORS: "BudgetPage.showDiffColors",
      IS_YEAR_COLLAPSED: "BudgetPage.isYearCollapsed",
    },
    TICKETS_PAGE: {
      IS_KANBAN_VIEW: "TicketsPage.isKanbanView",
      PERSON_FILTER: "TicketsPage.personFilter",
    },
    ASSET_IMAGES: {
      ASSET_DESIGN_MODE: "AssetImages.designMode",
      IMAGE_FIT: "AssetImages.imageFit",
    },
    TASKS_MONITOR: {
      ACTIVATED_CHANNELS_PROFILES: "TaskMonitor.activatedChannelProfiles",
      USER_STATUS: "TaskMonitor.userStatus",
    },
    DOCUMENTS: {
      HIDE_EMPTY_DOCS: "Documents.hideEmptyDocs",
      HIDE_OPTIONAL_DOCS: "Documents.hideOptionalDocs",
      SHOW_METADATA: "Documents.showMetadata",
    },
    SCRIPTS_PAGE: {
      SHOW_METADATA: "ScriptsPage.showMetadata",
      CODE_EDITOR_THEME: "ScriptsPage.codeEditorTheme",
      SETTINGS_PANEL_WIDTH: "ScriptsPage.settingsPanelWidth",
      EDITOR_PANEL_WIDTH: "ScriptsPage.editorPanelWidth",
      VIEWER_PANEL_WIDTH: "ScriptsPage.viewerPanelWidth",
    },
    PE_ASSET_PAGE: {
      IS_DIAGRAM_EXPANDED: "peAssetPage.isDiagramExpanded",
    },
    PANELS_PREFIX: "panels.",
  },
}

export function resetServices(args) {
  args = args || {}

  setDebugMode(false)

  resetLists()
  resetScripts()
  resetCache()
  consoleService.reset()
  if (args.logout) {
    localizationService.resetLocale()
  }

  if (args.keepUserConfiguration !== true) {
    unloadUserConfiguration()
    removeUserConfigurationStyles()
  }
}

// legacy, use getDebugMode instead, because importing a variable
// yields random hoisting errors
export let debug = false

export function getDebugMode() {
  return debug
}

function setDebugMode(value) {
  debug = value
}

export function isEmpty(s) {
  return !s
}

export async function downloadFileFromUrl(url, okMessage, koMessage, postData) {
  const response = postData ? await axios.post(url, postData, { responseType: "blob" }) : await axios.get(url, { responseType: "blob" })
  return downloadFileFromResponse(response, okMessage, koMessage)
}

export async function downloadFileFromResponse(response, okMessage /*koMessage*/) {
  try {
    let filename
    const disposition = response.headers["content-disposition"]
    if (disposition) {
      // Try to find out the filename from the content disposition `filename` value
      const matches = /"([^"]*)"/.exec(disposition)

      if (matches != null && matches[1]) {
        filename = matches[1]
      } else {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1]
        }
      }
    } else {
      filename = response.config?.url?.split("/").at(-1)
      const extension = filename?.split(".").at(-1)
      const contentType = response?.headers?.["content-type"]
      if (contentType.includes("pdf") && extension !== "pdf") filename += ".pdf"
      if (contentType.includes("spreadsheet") && extension !== "xlsx") filename += ".xlsx"
      if (contentType.includes("word") && extension !== "docx") filename += ".docx"
    }

    if (!filename) {
      addOops("No file name found")
      return
    }

    // The actual download
    const blob = new Blob([response.data])
    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = filename

    document.body.appendChild(link)

    link.click()

    // const url = window.URL.createObjectURL(new Blob([response.data]))
    // const link = report.createElement('a')
    // link.href = url
    // link.setAttribute('download', 'file.docx')
    // report.body.appendChild(link)
    // link.click()

    document.body.removeChild(link)

    addNotification(localizationService.loc(okMessage))
  } catch (error) {
    // in case of error we get a blob so we need to fix it to display a proper error
    try {
      if (error.response && error.response.data && error.response.data instanceof Blob) {
        let response = await new Response(error.response.data).text()
        let json = JSON.parse(response)
        error.response.data = json
      }
    } catch {} // eslint-disable-line
    addOops(error)
    throw error
  }
}

export function searchParamToObject(query) {
  let object = {}
  const searchParam = new URLSearchParams(query)
  for (let [field, value] of searchParam) object[field] = value
  return object
}

export function mergeQueryParams(search, params) {
  const query = new URLSearchParams(search)

  // Create obj from query
  const queryParams = {}
  for (let [key, value] of query.entries()) queryParams[key] = value

  // Override with params
  for (let key in params) {
    if (key?.startsWith(PROPS_)) continue
    if (params[key] === "" || params[key] === null) params[key] = undefined
    queryParams[key] = params[key]
  }

  // Remove undefined values
  Object.keys(queryParams).forEach(k => (queryParams[k] === undefined ? delete queryParams[k] : {}))

  // { key: "value" } => key=value
  const mergedQueryParams = Object.keys(queryParams)
    .map(k => `${k}=${queryParams[k]}`)
    .join("&")

  return `?${mergedQueryParams}`
}

export function toArray(str, separator = ",") {
  if (!str) return str
  return str
    .split(separator)
    .map(n => n.trim())
    .filter(n => n)
}

export function applyClasses(classes = {}) {
  return Object.keys(classes)
    .filter(key => key)
    .filter(cls => classes[cls])
    .join(" ")
    .trim()
}

// import shadowUrl from "_assets/img/marker-shadow.png";
// import iconUrl_red from "_assets/img/map_marker-red.png";
// import iconUrl_blue from "_assets/img/map_marker-blue.png";
// import iconUrl_orange from "_assets/img/map_marker-orange.png";
// import iconUrl_purple from "_assets/img/map_marker-purple.png";
// import iconUrl_green from "_assets/img/map_marker-green.png";
// import iconUrl_yellow from "_assets/img/map_marker-yellow.png";
// const iconColors = {
// red: iconUrl_red,
// blue: iconUrl_blue,
// orange: iconUrl_orange,
// purple: iconUrl_purple,
// green: iconUrl_green,
// yellow: iconUrl_yellow,
// };
// let mapMarkerIcons = {}
// export function getMapMarkerIcon(color) {
// mapMarkerIcons[color] =
// mapMarkerIcons[color] ||
// new Leaflet.Icon({
// iconUrl: iconColors[color], //: require("_assets/img/map_marker-" + color + ".png"),
// //iconRetinaUrl, //: require("_assets/img/map_marker-" + color + ".png"),
// iconAnchor: [20, 40],
// popupAnchor: [0, -35],
// iconSize: [27, 48],
// shadowUrl, //: require("_assets/img/marker-shadow.png"),
// shadowSize: [29, 40],
// shadowAnchor: [7, 40],
// })
// return mapMarkerIcons[color]
// }
let mapMarkerIcons = {}
export function getMapMarkerIcon(color) {
  mapMarkerIcons[color] =
    mapMarkerIcons[color] ||
    new Leaflet.divIcon({
      iconAnchor: [20, 40],
      popupAnchor: [-8, -35],
      iconSize: [27, 48],
      shadowSize: [29, 40],
      shadowAnchor: [7, 40],
      html: `<div style="--map-marker-color: var(--map-marker-color-${color})" />`,
    })
  return mapMarkerIcons[color]
}

let _organizationsObj
export function getOrganizationsObj() {
  return _organizationsObj || {}
}

export function getOrganizationsValues(isRich) {
  return Object.keys(_organizationsObj).map(key => {
    const value = key === "TENANT" ? "TENANT" : _organizationsObj[key].registration
    return {
      value,
      label: _organizationsObj[key].name + (isRich ? " (" + value + ")" : ""),
    }
  })
}

export function isTenantUseStandard() {
  return getOrganizationsObj()?.["TENANT"]?.useStandard !== false
}

export async function loadTenantPerson() {
  try {
    _organizationsObj = await getUriAndCacheResponse(`/api/person/organizations-with-ascendants`)
  } catch (error) {
    console.warn("Tenant person is not loaded", error)
  }
}

export function copyToClipboard(str) {
  try {
    navigator.clipboard.writeText(str)
  } catch (error) {
    addOops(error)
  }
}

export function convertToYaml(key, obj, indent = "", inArray) {
  let str = []
  if (Array.isArray(obj)) {
    str.push(indent + (key ? key + ": " : ""))
    for (let i = 0; i < obj.length; i++) {
      str.push(...convertToYaml("", obj[i], indent, true))
    }
  } else if (typeof obj === "object") {
    if (obj instanceof Date) {
      str.push(indent + key + ": " + formatDate(obj, localizationService.getLocale()))
    } else if (obj === null) {
      str.push(indent + (key ? key + ": " : "") + JSON.stringify(obj))
    } else {
      key && str.push(indent + key + ":")
      Object.keys(obj).forEach((subKey, i) => {
        str.push(...convertToYaml(subKey, obj[subKey], indent.replace(/-/, " ") + (inArray && i === 0 ? "  - " : "    ")))
      })
    }
  } else {
    str.push(indent + (inArray ? "  - " : "") + (key ? key + ": " : "") + (typeof obj === "string" ? '"' + obj + '"' : obj))
  }
  return str
}

export function toQueryParams(params) {
  const queryParams = {}

  for (const key in params || {}) {
    // Ignore "props_" query params
    if (key.startsWith(PROPS_)) continue

    // Ignore standard query params
    if (QUERY_PARAMS.includes(key)) continue

    queryParams[key] = params[key]
  }

  return queryParams
}

export async function getEntity(entityName, entityRegistration, params, options) {
  const { ignoreError } = options || {}
  const serverRoute = getEntityServerRoute(entityName)
  const queryParams = toQueryParams(params)
  const queryString = toQueryString(queryParams)

  try {
    const entity = await axiosGetData(`${serverRoute}/${entityRegistration}${queryString}`)
    return entity
  } catch (error) {
    if (!ignoreError) addOops(error)
    throw error
  }
}

export async function getEntities(entityName, params) {
  const serverRoute = getEntityServerRoute(entityName)
  const queryParams = toQueryParams(params)
  const queryString = toQueryString(queryParams)

  let entities = []
  try {
    entities = await axiosGetData(serverRoute + queryString)
  } catch (error) {
    addOops(error)
  }
  return entities
}

export async function searchEntities(entityName, search, filters = {}, projection) {
  const serverRoute = getEntityServerRoute(entityName)
  if (!serverRoute) return []

  if (search) search = search.trim()
  if (!search) return []

  let entities = []
  try {
    entities = (await axios.get(serverRoute + toQueryString({ search, ...filters, projection }))).data
  } catch (error) {
    addOops(error)
  }
  return entities
}

export function safeGet(obj, ...args) {
  for (let arg of args) {
    if (!obj) break
    obj = obj[arg]
  }
  return obj
}

/**
 * Attempts to find the MIME type of the supplied base 64 string based on so-called magic-number or magic-byte.
 * This is very unreliable so watch out so do not use that method for critical-mission purposes.
 */
function findBase64UrlMimeType(base64Url) {
  switch (base64Url.charAt(0)) {
    case "/":
      return "jpg"
    case "i":
      return "png"
    case "R":
      return "gif"
    case "U":
      return "webp"
    default:
      return null
  }
}

export function inferDataUriFromBase64(base64) {
  const indexOfBase64Part = base64.indexOf(",")
  if (indexOfBase64Part === -1) {
    const base64Url = base64.substring(indexOfBase64Part + 1)
    const mimeType = findBase64UrlMimeType(base64Url)
    if (mimeType) return `data:${mimeType};base64,${base64Url}`
    // returns nothing if the mime type is not found
  } else {
    // the data uri scheme is a priori already declared
    return base64
  }
}

export async function fileToBase64(file, options) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const { removeBase64Prefix = true } = options || {}
      resolve(
        removeBase64Prefix
          ? reader.result
              ?.replace("data:image/png;base64,", "")
              .replace("data:image/jpeg;base64,", "")
              .replace("data:image/svg+xml;base64,", "")
              .replace("data:application/pdf;base64,", "")
          : reader.result,
      )
    }
    reader.onerror = error => console.error("File to base64", error)
  })
}

export function toggleDebugMode(history) {
  const allowDebugMode = getOptions("allowDebugMode") ?? true
  if (!allowDebugMode) return
  setDebugMode(!debug)
  history.push(history.location.pathname + history.location.search)
  addNotification(localizationService.loc`Debug mode is now ${debug ? "ON" : "OFF"}`, "info")
}

export function applyPageAdvancedSearchConfig(componentName, searchFields) {
  const pageConfig = getPageConfig(componentName)
  if (pageConfig) {
    const { advancedSearch = {} } = pageConfig
    if (advancedSearch && Object.keys(advancedSearch).length > 0) {
      const advancedSearchProps = Object.fromEntries(Object.entries(advancedSearch).filter(([key]) => key.startsWith("props")))

      for (let advancedSearchProp in advancedSearchProps) {
        for (let i = 0; i < searchFields.length; i++) {
          const searchField = searchFields[i]
          for (let j = 0; j < searchField.length; j++) {
            const { field } = searchField[j]
            if (!field) continue
            if (PROPS_ + field === advancedSearchProp) {
              searchFields[i][j] = {
                ...searchField[j],
                ...advancedSearch[advancedSearchProp],
              }
            }
          }
        }
      }
    }
    const { fields } = advancedSearch || {}
    if (Array.isArray(fields)) {
      fields.map(it => {
        if (!searchFields.includes(it)) searchFields.push(it)
      })
    }
  }
  return searchFields
}

export function checkRegistrationFormat(checkFormatCode, value, regexp) {
  if (!value) return null
  if (checkFormatCode === "iban") {
    if (!isValidIban(value)) {
      return localizationService.loc`IBAN is invalid`
    }
  }
  if (regexp) {
    if (!value.toString().match(regexp)) {
      return localizationService.loc`Invalid format`
    }
  } else if (["EVA", "EV-"].includes(checkFormatCode)) {
    return isValidEva(value)
  } else if (["SPANISHID"].includes(checkFormatCode?.toUpperCase())) {
    const { type, isValid } = validateSpanishId(value)
    if ((type !== "DNI" && type !== "NIE") || !isValid) return localizationService.loc`Spanish ID is invalid`
  }
  return null
}

function isValidEva(eva) {
  if (!eva) return null
  if (!/^[A-Z][A-Z]/.test(eva)) return localizationService.loc`Must start with two letters`
  if (eva.startsWith("FR")) {
    if (!/^FR[0-9A-Z]{2}[0-9]{9}$/.test(eva)) return "FRXX000000000"
  } else if (eva.startsWith("LT")) {
    if (!/^LT[0-9]{9}$/.test(eva) && !/^LT[0-9]{12}$/.test(eva)) return "FR000000000, FR000000000000"
  }
  // we should complete all EU countries according to https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11
  return true
}

export function labelFromName(name, { isTranslated = true } = {}) {
  if (!name) return ""
  if (name.includes(".")) name = name.split(".").pop() // Support nested field names
  let label = ""
  for (let i = 0; i < name.length; i++) {
    if (i === 0) {
      label += name[0].toUpperCase()
    } else if (name[i] >= "A" && name[i] <= "Z") {
      label += " " + name[i].toLowerCase()
    } else {
      label += name[i]
    }
  }
  if (isTranslated) return localizationService.loc([label])
  return label
}

export function urlFromName(str) {
  // ContractLots => contract-lots
  str = str.replace(/[A-Z]/g, (x, d) => (d > 0 ? "-" : "") + x.toLowerCase())
  return str
}

/**
 * Columns definitions can have the properties excelWidth and excelFormat to help display nicely the data.
 * For dates, if the field is true date, no need to pass excelFormat = date
 * Standard supported formats by the report service are currency "#,##0.00" and percentage "0.0000%"
 * If you need more can pass directly a pattern, read https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
 *
 * Sheet names cannot exceed 31 characters (Excel limit).
 *
 * The option removeLastNrows is when you have data with incomplete rows like when displaying totals at the bottom of tables.
 * If you don't remove these rows when opening the file Excel will complain that the file is corrupted and propose an attempt to repair it.
 *
 * React elements aren't totally for export (some are, see the scope covered in the code below about the "definitiveItem").
 * In case you cannot or don't to add a parser, you can set :
 *   on the column that has an issue : excelHidden = true
 *   and create a second column that holds the value you want to export with : hidden = true, excelHidden = false
 */
export async function exportToExcel({ url, data, columns, fileName, sheetName, removeLastNrows = 0 }) {
  if (url) {
    await downloadFileFromUrl(url, localizationService.loc`File downloaded`, localizationService.loc`Downloading file`)
    return
  }

  const displayedColumns = columns
    .filter(col => (col?.hidden !== true && col?.excelHidden !== true) || col?.excelHidden === false)
    .map(it => (typeof it === "string" ? { title: labelFromName(it), name: it } : it))
    .map(it => ({ ...it }))
  const locale = localizationService.getLocale()

  if (!sheetName) sheetName = fileName

  displayedColumns.forEach(dc => {
    if (!dc.excelFormat) {
      if (dc.type === "currency") dc.excelFormat = "currency"
      else if (dc.type === "date") dc.excelFormat = "date"
    }
  })

  const parseIconItem = item => {
    if (item.props?.className?.indexOf("icn-check") !== -1) {
      return "✓"
    } else {
      return "-"
    }
  }

  const concatChildren = (children, result) => {
    if (!result) result = ""
    for (const child of children) {
      if (child) {
        if (Array.isArray(child.props?.children)) result += concatChildren(child.props.children, result)
        else if (child.props?.children) result += child.props.children
        else result += child
      }
    }
    return result
  }

  if (typeof data === "string" && data?.startsWith("/api")) data = (await axios.get(data)).data

  const exportedData = data.map(dataItem => {
    const exportedDataItem = {}

    displayedColumns.forEach(displayedColumn => {
      let foundItem = get(dataItem, displayedColumn.name)
      if (foundItem?.content) foundItem = foundItem.content
      const foundChildren = foundItem?.props?.children
      const typeOfFoundItem = typeof foundItem

      let definitiveItem = ""
      if (foundChildren) {
        if (Array.isArray(foundChildren)) {
          definitiveItem = concatChildren(foundChildren)
        } else {
          if (foundChildren.type === "i") {
            definitiveItem = parseIconItem(foundChildren)
          } else if (foundChildren.props?.field && foundChildren.props?.obj) {
            definitiveItem = foundChildren.props.obj[foundChildren.props.field] || ""
          } else {
            definitiveItem = foundChildren
          }
        }
      } else if (foundItem?.props?.field && foundItem?.props?.obj) {
        definitiveItem = foundItem.props.obj[foundItem.props.field] || ""
      } else if (foundItem?.props?.value) {
        definitiveItem = foundItem.props.value
      } else if (foundItem?.type === "i") {
        definitiveItem = parseIconItem(foundItem)
      } else if (foundItem?.props?.select) {
        definitiveItem = getLabel(foundItem.props.select, foundItem.props.value)
      } else if (["string", "number", "date", "boolean"].includes(typeOfFoundItem)) {
        definitiveItem = foundItem
      } else if (typeof foundItem?.getMonth === "function") {
        // a date object
        definitiveItem = foundItem //.toLocaleDateString(localizationService.getLocale())
      }
      // the ultimate else would be for item of type react element, which is a type unsuitable for export

      let exportedDataValue = ""
      if (displayedColumn.select) {
        exportedDataValue = getLabel(displayedColumn.select, definitiveItem)
      } else if (displayedColumn.excelFormat === "currency") {
        exportedDataValue = coerceStringToJsNumber(definitiveItem, locale)
      } else if (typeof definitiveItem === "string") {
        if (foundItem?.props?.select) {
          exportedDataValue = getLabel(foundItem.props.select, definitiveItem)
        } else {
          exportedDataValue = localizationService.loc(definitiveItem)
        }
      } else {
        exportedDataValue = definitiveItem
      }

      exportedDataItem[displayedColumn.name] = exportedDataValue
    })

    return exportedDataItem
  })

  const nbOfRows = exportedData.length
  if (removeLastNrows > 0 && removeLastNrows < nbOfRows) {
    exportedData.splice(nbOfRows - removeLastNrows)
  }

  const excelColumns = {}
  displayedColumns.forEach(({ name, title, excelWidth, excelFormat }) => {
    excelColumns[name] = {
      headerLabel: localizationService.loc(title),
      width: excelWidth,
      format: excelFormat,
    }
  })

  await downloadFileFromUrl("/api/report/excel", localizationService.loc`File downloaded`, localizationService.loc`Downloading file`, {
    fileName,
    [sheetName]: {
      columns: excelColumns,
      data: exportedData,
    },
    // to add more sheets add more keys like the one above
  })
}

/**
 * Warning: accessing window.parent.location.origin in a cross-origin situation throws an error
 * so we must wrap the code. Below is the exception as shown in Google Chrome.
 * DOMException: Failed to read a named property 'origin' from 'Location': Blocked a frame with origin "someUrl" from accessing a cross-origin frame.
 */
export function isSameOrigin() {
  try {
    return window.location.origin === window.parent.location.origin
  } catch (error) {
    console.warn(error)
  }
}

export function checkIfInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function openDocInTab({ base64, blob, docName = "" } = {}) {
  if (!base64 && !blob) return

  const openedWindow = window.open()
  if (!openedWindow) return

  openedWindow.document.write(
    `<html><head><title>${docName}</title></head><body style="margin: 0">` +
      `<iframe src="${
        base64 ?? URL.createObjectURL(blob)
      }" frameborder="0" style="border:0; bottom:0; height:100%; left:0; right:0; top:0; width:100%;" allowfullscreen></iframe>` +
      "</body></html>",
  )
}

// "/supplier-asset-lots" => "/asset-lots"
export function getClientAliasRoute(clientRoute) {
  const clientRoutes = getConfigAtPath("clientRoutes")
  if (!clientRoutes) return clientRoute

  for (let key in clientRoutes)
    if (clientRoutes[key].clientRoute === clientRoute && clientRoutes[key].clientAliasRoute) return clientRoutes[key].clientAliasRoute
}

// "/supplier-asset-lots" => "supplierAssetLotsPage"
export function getClientRouteKey(clientRoute) {
  const clientRoutes = getConfigAtPath("clientRoutes")
  if (!clientRoutes) return

  for (const key in clientRoutes) if (clientRoutes[key].clientRoute === clientRoute) return key
}

export async function readBlobAsync(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export function getRandomInt(min = 1, max = 512) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

export function isPromise(promise) {
  return !!promise && typeof promise.then === "function"
}

export function getQueryParam(props, param) {
  const queryParams = searchParamToObject(props.location.search)
  return queryParams[param]
}

export function getQueryParams(props, params) {
  const queryParams = searchParamToObject(props.location.search)
  return params.map(param => queryParams[param])
}

export function hasQueryParamChanged(props, prevProps, param) {
  const queryParams = searchParamToObject(props.location.search)
  const prevQueryParams = searchParamToObject(prevProps.location.search)

  return queryParams[param] !== prevQueryParams[param]
}

/**
 * @param {Object} param1 Object containing the current search params
 * @param {Object} param2 Object containing the previous search params
 * @param {Array[String]} ignoreParams params to be ignored in addition to the QUERY_PARAMS
 * @returns True or false depending of whether or not the current and previous params match.
 */
export function hasQueryParamsChanged({ location: { search } }, { location: { search: prevSearch } }, ignoreParams = []) {
  const queryParams = searchParamToObject(search)
  const prevQueryParams = searchParamToObject(prevSearch)

  let params = Object.keys(queryParams).concat(Object.keys(prevQueryParams))
  params = Array.from(new Set(params)) // Remove duplicates
  params = params.filter(param => ![...QUERY_PARAMS, ...ignoreParams].includes(param))

  for (const param of params) if (queryParams[param] !== prevQueryParams[param]) return true
  return false
}

export function isMonthInterval(fromDate, toDate) {
  if (fromDate?.getDate() !== 1) return
  if (new Date(toDate?.getTime() + 86400000).getDate() !== 1) return
  return fromDate.getFullYear() + fromDate.getMonth() === toDate.getFullYear() + toDate.getMonth()
}

/**
 * This function does not take into account the hour part.
 */
export function isWeekInterval(fromDate, toDate) {
  // humans use week ranges by stating the date of the first day of the week and the date of its last day
  // for instance : the week from 14/03/2022 to 20/03/2022
  // the counting of days is 14, 15, 16, 17, 18, 19, and 20 (7)
  // the difference between two numbers is an interval
  // the week interval is 6 (we are not checking the duration of the week)
  return (new Date(toDate?.toJSON().substr(0, 10)).getTime() - new Date(fromDate?.toJSON().substr(0, 10)).getTime()) / (1000 * 3600 * 24) === 6
}

/**
 * When the app is running in compiled form,
 * the correct displayed version (aka the "external version") has the format "YYYYMMDD-hhmmss vA.B.C"
 * where YYYYMMDD-hhmmss is the build datetime of line 1 of file version.txt
 * and vA.B.C is the release tag inscribed at line 2 of the same file.
 * There is also the "internal version" that comes from the env variables.
 * The internal and external build datetimes can have different values and both are expected
 * to be created externally, typically by the CICD.
 */
export function getAppVersion() {
  const { REACT_APP_BUILD_DATETIME } = process.env
  const storedAppVersion = localStorage.getItem(localStorageKeys.APP_VERSION)
  const buildDatetimeExternal = storedAppVersion?.substring(0, storedAppVersion?.indexOf(" "))
  if (REACT_APP_BUILD_DATETIME) console.debug(`IBT ${REACT_APP_BUILD_DATETIME}`)
  if (buildDatetimeExternal) console.debug(`EBT ${buildDatetimeExternal}`)
  return storedAppVersion
}

export const allDomTags = [
  "a",
  "abbr",
  "acronym",
  "address",
  "applet",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "basefont",
  "bdi",
  "bdo",
  "big",
  "blockquote",
  "body",
  "br",
  "button",
  "canvas",
  "caption",
  "center",
  "cite",
  "code",
  "col",
  "colgroup",
  "data",
  "datalist",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "dir",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "font",
  "footer",
  "form",
  "frame",
  "frameset",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hr",
  "html",
  "i",
  "iframe",
  "img",
  "input",
  "ins",
  "kbd",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "meta",
  "meter",
  "nav",
  "noframes",
  "noscript",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "script",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strike",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "svg",
  "table",
  "tbody",
  "td",
  "template",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "tt",
  "u",
  "ul",
  "var",
  "video",
  "wbr",
]

export function scrollToModelField(modelFieldPath) {
  const cards = document.querySelectorAll(".card")
  for (let i = 0; i < cards?.length; i++) {
    cards[i].dispatchEvent(new CustomEvent(customEvents.card.scrollToModelField, { detail: { modelFieldPath } }))
  }
}

/**
 * Most browsers prevent using onbeforeunload if the user has not interacted with the page yet
 * so we must create the listener only when we are sure the user has willingly done something.
 * For now we don't use this in Pages and Content.
 */
export function setBeforeUnloadListener() {
  if (window.onbeforeunload || !getOptions("showConfirmationPopupOnWindowUnload")) return
  window.onbeforeunload = () => {
    const entityClientRoutes = getEntityClientRoutes()
    for (const entityName in entityClientRoutes) {
      if (window.location.pathname.startsWith(entityClientRoutes[entityName])) {
        return " " // a string needs to be returned but it is actually not used for display
      }
    }
  }
}

// Add page config "fields" to entities
export function applyPageConfigFields(pageConfig, entities) {
  if (!Array.isArray(pageConfig?.fields) || !pageConfig?.fields?.length) return entities

  const fields = pageConfig?.fields.filter(field => field.name && (field.path || field.value))
  if (!fields.length) return entities

  return entities.map(entity => {
    for (const field of fields) {
      if (field.value) entity[field.name] = field.value
      else if (field.path) entity[field.name] = get(entity, parsePath(entity, field.path))
    }
    return entity
  })
}

// Ex: "persons[role=OWNER].personRegistration" => "persons[x].personRegistration"
function parsePath(entity, path) {
  if (path.includes("=")) {
    let parsedPath = ""

    const parts = path
      .split("[")
      .map(subPath => subPath.split("]"))
      .flat() // [ 'persons', 'role=OWNER', '.personRegistration' ]
    for (let [index, part] of parts.entries()) {
      // role=OWNER
      if (part.includes("=") && index > 0) {
        const [key, value] = part.split("=") // ["role", "OWNER"]
        const subEntities = get(entity, parts[index - 1]) // "entity.persons"
        if (Array.isArray(subEntities)) {
          const subEntityIndex = subEntities.findIndex(e => e[key] === value)
          parsedPath += `[${subEntityIndex}]`
        }
      } else parsedPath += part
    }

    return parsedPath
  }
  return path
}

export const selfIframeSources = {
  SIDE_VIEW: "SIDE_VIEW",
}

export const selfIframeMessages = {
  READY: "READY",
}

export const customEvents = {
  card: {
    toggle: "card:toggle",
    scrollToModelField: "card:scroll-to-model-field",
    openTabCallback: "card:open-tab-callback",
  },
  innerPanel: {
    open: "inner-panel:open",
  },
  personComponent: {
    openTab: "persons-component:open-tab",
  },
  navsCard: {
    displayAllNavs: "navs-card:display-all-navs",
    syncActiveNav: "navs-card:sync-active-nav",
    requestActiveNav: "navs-card:request-active-nav",
  },
  user: {
    photoHasChanged: "user:photo-has-changed",
  },
  pagesLayout: {
    reloadRoute: "pages-layout:reload-route",
  },
  sideView: {
    setUrl: "side-view:set-url",
    toggleView: "side-view:toggle-view",
  },
}

/**
 * @param {Object} params
 * @param {Function} params.getField Function generating fields
 * @param {Array[]} params.rows Array of array representing rows containing cols containing fields
 * @param {Object} params.entity Entity of the fields
 * @param {function} params.handleSetState Function to set the entity state for the fields
 * @param {string} params.readOnly readOnly of the entity
 * @param {string} params.modelPath Model path of the entity
 * @param {number} [params.index] Optional index if the entity is part of an array
 * @param {Object} [params.handlers] Optional handlers to update field outside of the entity
 * @returns Array of <Row></Row>
 */
export function generateRows({ getField, rows, entity, handleSetState, readOnly, modelPath, index, handlers }) {
  if (typeof rows === "string") rows = [[rows]]
  return rows.map((row, _index) => (
    <Row key={_index}>{generateCols({ getField, row, entity, handleSetState, readOnly, modelPath, index, handlers })}</Row>
  ))
}

function generateCols({ getField, row, entity, handleSetState, readOnly, modelPath, index, handlers }) {
  const params = { getField, entity, handleSetState, readOnly, modelPath, index, handlers }
  if (typeof row === "string") {
    // the field handles the <Col> itself
    return generateCell({ cell: { name: row }, ...params })
  } else if (Array.isArray(row)) {
    row = row.filter(it => it)
    const defaultColProps =
      row.length === 1 ? { xs: 12 } : row.length === 2 ? { xs: 12, sm: 6 } : row.length === 3 ? { xs: 12, sm: 6, md: 4 } : { xs: 12, sm: 6, md: 3 }
    return row.map((col, key) => {
      if (typeof col === "string") {
        // the field handles the <Col> itself
        return generateCell({ cell: { name: col }, ...params })
      } else if (Array.isArray(col)) {
        return (
          <Col key={key} {...defaultColProps}>
            {generateRows({ rows: col, ...params })}
          </Col>
        )
      } else if (typeof col === "object") {
        if (col.rows) {
          // an object { colProps, rows }, allows to change the colProps
          return (
            <Col key={key} {...(col.colProps || defaultColProps)} className={col.className || ""}>
              {generateRows({ rows: col.rows, ...params })}
            </Col>
          )
        } else {
          // the field handles the <Col> itself
          return generateCell({ cell: col, ...params })
        }
      } else {
        // assume null
        return null
      }
    })
  } else if (row) {
    const { title, titleLinkTo, collapse, rows, name, className } = row
    if (name) return generateCell({ cell: row, ...params })
    const content = Array.isArray(rows) ? generateRows({ rows, ...params }) : null
    // formInputProps & buttonProps are provided without title
    // They aren't put in a panel so that their layout can controlled finely by configuration.
    return title ? (
      <Col xs={12}>
        <PanelInner collapse={collapse} title={title} titleLinkTo={titleLinkTo} className={className}>
          {content}
        </PanelInner>
      </Col>
    ) : (
      content
    )
  }
}

function generateCell({ getField, cell, entity, handleSetState, readOnly, modelPath, index, handlers }) {
  const inputEvents = ["action", "onBlur", "onFocus"]
  for (const eventName of inputEvents) {
    const eventFunction = cell.formInputProps?.[eventName]
    if (typeof eventFunction === "function") {
      cell.formInputProps[eventName] = async event => {
        const eventPatch = await eventFunction({ event, pageState: cloneDeep(entity) })
        if (eventPatch) handleSetState(eventPatch)
      }
    }
  }
  if (cell.name === "spacer")
    return (
      <Col key={uuidv4()} xs={12}>
        <hr></hr>
      </Col>
    )
  let cellComponent = getField(cell, entity, handleSetState, index)
  if (!cellComponent) {
    const { name, formInputProps } = cell
    if (!name && formInputProps) {
      const key = [cell.title, formInputProps?.field, formInputProps?.label, cell.buttonProps?.uri, cell.buttonProps?.label].filter(it => it).join("")
      cellComponent = (
        <FormContent
          key={key}
          obj={entity}
          field={cell}
          handlers={handlers}
          onSetState={handleSetState}
          execComputations={cell.execComputations}
          readOnly={formInputProps.readOnly ?? cell.readOnly ?? readOnly}
          modelPath={formInputProps.modelPath || modelPath}
        />
      )
    }
  }
  return cellComponent
}

let navigationRef
export function setNavigationRef(ref) {
  navigationRef = ref
}

export function getNavigationRef() {
  return navigationRef
}

function addCheckConfigNotifications({ settingsType, inserts = [], updates = [], deletes = [], userUpdated = [] }) {
  const messageMaxLength = 200
  const nbOfInserts = inserts.length
  const nbOfUpdates = updates.length
  const nbOfUserUpdates = userUpdated.length
  const nbOfDeletes = deletes.length

  if (!nbOfInserts && !nbOfUpdates && !nbOfDeletes && !nbOfUserUpdates) return

  const options = { autoDismiss: 15, level: "info" }
  if (nbOfInserts) {
    let insertsString = inserts.join(", ")
    if (insertsString.length > messageMaxLength) insertsString.substring(0, messageMaxLength) + " [...]"
    options.message = `Added ${nbOfInserts} ${settingsType}: ${insertsString}`
    addNotification(options)
  }
  if (nbOfUpdates) {
    let updatesString = updates.join(", ")
    if (updatesString.length > messageMaxLength) updatesString.substring(0, messageMaxLength) + " [...]"
    options.message = `Updated ${nbOfUpdates} ${settingsType}: ${updatesString}`
    addNotification(options)
  }
  if (nbOfUserUpdates) {
    let updatesString = userUpdated.join(", ")
    if (updatesString.length > messageMaxLength) updatesString.substring(0, messageMaxLength) + " [...]"
    console.log(updatesString)
    options.message = `Updated ${nbOfUserUpdates} user ${settingsType}: ${updatesString}`
    addNotification(options)
  }
  if (nbOfDeletes) {
    let deletesString = deletes.join(", ")
    if (deletesString.length > messageMaxLength) deletesString.substring(0, messageMaxLength) + " [...]"
    options.message = `Deleted ${nbOfDeletes} ${settingsType}: ${deletesString}`
    addNotification(options)
  }
}

let isCheckingTenantConfig = false
export async function checkTenantConfig({ allTenants, softReload = true } = {}) {
  if (!canCheckTenantConfig() || isCheckingTenantConfig) return

  try {
    isCheckingTenantConfig = true
    const {
      errors = [],
      insertedLists = [],
      updatedLists = [],
      deletedLists = [],
      insertedScripts = [],
      updatedScripts = [],
      deletedScripts = [],
      insertedReports = [],
      updatedReports = [],
      deletedReports = [],
      userLists = [],
      userScripts = [],
      lastConfigCheckDate,
    } = (await axios.get("/api/core/tenants/check-config" + objectToSearchParam({ allTenants }))).data || {}

    for (const error of errors) addOops(error)

    const touchedLists = [...insertedLists, ...updatedLists, ...deletedLists]
    const touchedScripts = [...insertedScripts, ...updatedScripts, ...deletedScripts]
    const touchedReports = [...insertedReports, ...updatedReports, ...deletedReports]
    const userUpdatedLists = []
    const userUpdatedScripts = []

    const lastLoadingDate = getLoadingDate()
    if (userLists.length) {
      for (let i = 0; i < userLists.length; i++) {
        const { _updateDate, name } = userLists[i]
        if (_updateDate >= lastLoadingDate && !touchedLists.find(touchedList => touchedList === name)) {
          touchedLists.push(name)
          userUpdatedLists.push(name)
        }
      }
    }
    if (userScripts.length) {
      for (let i = 0; i < userScripts.length; i++) {
        const { _updateDate, name } = userScripts[i]
        if (_updateDate >= lastLoadingDate && !touchedScripts.find(touchedScript => touchedScript === name)) {
          touchedScripts.push(name)
          userUpdatedScripts.push(name)
        }
      }
    }

    addCheckConfigNotifications({
      settingsType: "lists",
      inserts: insertedLists,
      updates: updatedLists,
      deletes: deletedLists,
      userUpdated: userUpdatedLists,
    })
    addCheckConfigNotifications({
      settingsType: "scripts",
      inserts: insertedScripts,
      updates: updatedScripts,
      deletes: deletedScripts,
      userUpdated: userUpdatedScripts,
    })
    addCheckConfigNotifications({ settingsType: "reports", inserts: insertedReports, updates: updatedReports, deletes: deletedReports })

    const nbOfTouchedLists = touchedLists.length
    const nbOfTouchedScripts = touchedScripts.length
    const nbOfTouchedReports = touchedReports.length

    const isConfigStale = lastConfigCheckDate && lastConfigCheckDate > lastLoadingDate
    if (nbOfTouchedLists > 0 || nbOfTouchedScripts > 0 || isConfigStale) {
      if (isConfigStale) {
        if (!nbOfTouchedLists && !nbOfTouchedScripts) {
          addNotification({ message: "Configuration is stale", level: "warning" })
        }
      }

      resetLists({ itemsToReset: updatedLists })

      // Reset all scripts cache instead of just the ones updated.
      // This is necessary because we can request a typed entity layout which may return the standard entity layout
      // and store it in cache under the typed entity layout script name.
      // Consequently, if the standard entity layout script is flagged as updated in the check config response,
      // the cache for the typed entity layout is not cleared, requiring a manual refresh to update the cache
      resetScripts()

      resetCache()

      if (touchedScripts.includes("user-configuration") || isConfigStale) {
        // give some time for the server to refresh its cache
        await sleep(1000)
        await loadUserConfiguration()
        await applyUserConfigurationStyles({ implementation: getTenant() })
      }
      if (softReload) {
        window.dispatchEvent(new CustomEvent(customEvents.pagesLayout.reloadRoute))
      }
    } else if (!nbOfTouchedReports) {
      addNotification({ message: "Configuration is up to date" })
    }
  } catch (error) {
    addOops(error)
  } finally {
    isCheckingTenantConfig = false
  }
}

/**
 * Simply calling new Date() or toLocaleString or even toString()
 * can introduce characters that depends on the caller OS and that
 * are not supported in some places like HTTP headers.
 * Use this function to ensure that the date is kept with the timezone while
 * being compatible in all cases.
 */
export function getDateStringWithOffset(date) {
  const currentDate = date || new Date()
  const isoDateString = currentDate.toISOString()

  // Extract the date and time part (without milliseconds and Z)
  const dateAndTime = isoDateString.slice(0, 19)

  // Extract the timezone offset in the format ±HH:mm
  const timezoneOffset = currentDate.getTimezoneOffset()
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
  const offsetMinutes = Math.abs(timezoneOffset) % 60
  const offsetString =
    (timezoneOffset >= 0 ? "-" : "+") + (offsetHours < 10 ? "0" : "") + offsetHours + ":" + (offsetMinutes < 10 ? "0" : "") + offsetMinutes

  return dateAndTime + offsetString
}

export function isNewTabOrWindowClick(event) {
  const isMac = window.navigator.userAgentData?.platform === "macOS" || window.navigator.platform?.match("Mac")
  return (isMac ? event.metaKey : event.ctrlKey) || event.shiftKey || event.button === 1
}

/**
 * Examples for setting image dimensions:
 *  ![my pic](my_pic.png=300x)
 *  ![my pic](my_pic.png=300x400)
 *  ![my pic](my_pic.png=x400)
 *
 * Syntax is: =WidthxHeight
 */
export function getMarkdownImageOptions({ href, title, altText }) {
  let hrefParts = href.split("=")
  if (hrefParts.length === 1) hrefParts = href.split(";")
  if (hrefParts.length === 1) hrefParts = href.split(",")

  let imgHeight = ""
  let imgWidth = ""
  if (hrefParts[1]) {
    const size = hrefParts[1].split("x")
    imgHeight = `height="${size[1]}"`
    imgWidth = `width="${size[0]}"`
  }

  const imgSrc = hrefParts[0]
  return {
    imgSrc,
    imgHeight,
    imgWidth,
    tag: `<img ${altText ? `alt="${altText}"` : ""} title="${title || altText}" ${imgHeight} ${imgWidth} src="${imgSrc}" />`,
  }
}

addHook("beforeSanitizeAttributes", function (node) {
  if ("target" in node) {
    node.setAttribute("data-target", node.getAttribute("target"))
  }
})

addHook("afterSanitizeAttributes", function (node) {
  if ("target" in node) {
    const target = node.getAttribute("data-target")
    if (target === "_blank") {
      // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
      node.setAttribute("target", target)
      node.setAttribute("rel", "noopener noreferrer")
    }
    node.removeAttribute("data-target")
  }
})

/**
 * Take good note of this doc https://marked.js.org/using_pro#use
 * When calling use(), if we return the value "false" then the previous override in the load chain is used.
 */
export function setMarkedRenderedOptions() {
  const documentationRoute = "/documentation"
  const legacyApiDocRoot = "/api/doc/"

  marked.use({
    renderer: {
      link(href, title, text) {
        // When we want to display bare URL markdown linters forces us to
        // repeat the link both in the text and href parts [some-link](some-link).
        // To avoid that we support [](some-link) and the text part automatically gets the href.
        if (!text && href) text = href

        let optionalTags = ""
        let hasNewTabOption
        if (title) {
          // We only support target=_blank, no need to support other values as they are rare
          const strippedTitle = title.replaceAll("[newTab]", () => {
            hasNewTabOption = true
            return ""
          })
          if (strippedTitle) optionalTags += ` title="${strippedTitle}"`
          if (hasNewTabOption) {
            if (optionalTags) optionalTags += " "
            optionalTags += ` target="_blank"`
          }
        }

        const { pathname } = window.location
        if (pathname.startsWith(`${documentationRoute}/`) || pathname === documentationRoute) {
          const hashIndex = window.location.href.indexOf("#")

          setTimeout(() => (document.getElementsByTagName("body")[0].scrollTop = 0))

          return href.startsWith("http")
            ? `<a${optionalTags} href="${href}">${text}</a>`
            : href.startsWith("#")
            ? `<a${optionalTags} href="${
                (hashIndex !== -1 ? window.location.href.substring(0, hashIndex) : window.location.href) + href
              }">${text}</a>`
            : href.startsWith(".")
            ? `<a${optionalTags} href="${window.location.pathname + "/../" + href}">${text}</a>`
            : href.startsWith("/")
            ? `<a${optionalTags} href="${hasNewTabOption ? window.location.origin : ""}${href}">${text}</a>`
            : `<a${optionalTags} href="${`${documentationRoute}/${href.replace(
                ".md",
                "",
              )}`}" data-original-href="${`${documentationRoute}/${href}`}">${text}</a>`
        }

        return `<a${optionalTags} href="${href}">${text}</a>`
      },
      table(header, body) {
        return `<table class="table">${header + body}</table>`
      },
      code(code, language) {
        if (language === "mermaid") {
          const id = `mermaid${Date.now()}` // needs a unique element id
          return mermaid.mermaidAPI.render(id, code)
        } else {
          return `<pre><code>${hljs.highlight(code, { language: hljs.getLanguage(language) ? language : "plaintext" }).value}</code></pre>`
        }
      },
      image(href, title, altText) {
        let { tag, imgSrc, imgHeight, imgWidth } = getMarkdownImageOptions({ href, title, altText })

        const { pathname } = window.location
        if (pathname.startsWith(`${documentationRoute}/`) || pathname === documentationRoute) {
          const docPath = window.location.pathname.replace(documentationRoute, "")
          const docFolder = docPath.substr(0, docPath.lastIndexOf("/") + 1)
          imgSrc = window.location.origin + legacyApiDocRoot + "integration" + docFolder + imgSrc
          const src = (href.startsWith("http") || href.startsWith("/") ? href : imgSrc) + `?token=${getAuthorizationToken()}`

          return `<img ${altText ? `alt="${altText}"` : ""} title="${title || altText}" ${imgHeight} ${imgWidth} src="${src}" />`
        }

        return tag
      },
    },
  })
}

export function formatCurrency(number, locale, options = {}) {
  const currencyDisplay = getConfigAtPath("currencyDisplay")
  return _formatCurrency(number, locale, {
    currencyDisplay,
    ...(options === 0 ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : typeof options === "string" ? { currency: options } : options),
  })
}

export function parseFrequencyValue(frequency, { usePlural } = {}) {
  const isDaily = frequency === "1"
  const isWeekly = frequency === "7"
  const isMonthly = frequency === "30"
  const isQuarter = frequency === "90"
  const isSemester = frequency === "180"
  const isAnnual = ["365", "360"].includes(frequency)
  return {
    isDaily,
    isWeekly,
    isMonthly,
    isQuarter,
    isSemester,
    isAnnual,
    label: isDaily
      ? localizationService.loc(usePlural ? "days" : "day")
      : isWeekly
      ? localizationService.loc(usePlural ? "weeks" : "week")
      : isMonthly
      ? localizationService.loc(usePlural ? "months" : "month")
      : isQuarter
      ? localizationService.loc(usePlural ? "quarters" : "quarter")
      : isSemester
      ? localizationService.loc(usePlural ? "semesters" : "semester")
      : isAnnual
      ? localizationService.loc(usePlural ? "years" : "year")
      : "",
  }
}

/**
 * This function is important for web accessibility.
 */
export function handleAccessibleOnKeyDown({ event, history, fn, linkTo }) {
  if (event.key === " " || event.key === "Enter") {
    event.preventDefault()
    event.stopPropagation()

    if (linkTo) {
      history.push(linkTo)
      if (fn) fn()
    } else if (fn) {
      fn()
    }
  }
}

export function onDropdownToggleKeyDown(event) {
  const isBottomArrowKey = event.keyCode === 40
  if (isBottomArrowKey) {
    const { target } = event
    target.nextSibling?.children?.[0].focus()
  }
}

export function onDropdownMenuButtonKeyDown(event) {
  const isTopArrowKey = event.keyCode === 38
  const isBottomArrowKey = event.keyCode === 40
  if (isBottomArrowKey) {
    const { target } = event
    target.nextSibling?.focus()
  } else if (isTopArrowKey) {
    const { target } = event
    target.previousSibling?.focus()
  }
}

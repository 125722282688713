import axios from "axios"
import { checkErrors, getEntityServerRoute, hasErrors, objectToSearchParam, PROPS_, replaceVariables } from "basikon-common-utils"
import React from "react"
import { Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import CustomButton from "@/_components/CustomButton"
import DebugPopover from "@/_components/DebugPopover"
import DocumentsModal from "@/_components/DocumentsModal"
import WorkflowCommentModal from "@/_components/WorkflowCommentModal"

import consoleService from "@/_services/console"
import { setEntityFieldProps } from "@/_services/entity"
import { getItem, getList, getValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getProfiles } from "@/_services/userConfiguration"
import { customEvents, debug, downloadFileFromUrl, labelFromName, onDropdownMenuButtonKeyDown, onDropdownToggleKeyDown } from "@/_services/utils"

class EntityWorkflowButtons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      transitions: [],
      documents: [],
      foreignDocuments: [],
      debug: debug,
      isLoading: false,
      loadingDocuments: false,
      loadingforeignDocuments: false,
      serverRoute: props.serverRoute || getEntityServerRoute(props.entityName),
      selectedTransition: undefined,
      modalDocument: undefined,
    }
  }

  async componentDidMount() {
    const transitions = await this.getTransitions()
    this.setState({ transitions })

    const hasDocuments = (transitions || []).filter(t => t.documentTypesList).length > 0
    if (hasDocuments) await this.getDocuments()
  }

  componentWillUnmount() {
    const { intervalId } = this.state
    if (intervalId) clearInterval(intervalId)
  }

  async componentDidUpdate(prevProps) {
    const { entity, warnings, errors } = this.props
    const { entity: prevEntity = {} } = prevProps

    if (warnings?.length > 0 || errors?.length > 0) window.dispatchEvent(new CustomEvent(customEvents.navsCard.displayAllNavs))

    if (
      (entity.status && entity.status !== prevEntity.status) ||
      (entity.type && entity.type !== prevEntity.type) ||
      (entity.registration && entity.registration !== prevEntity.registration && entity.status && entity.status !== "DRAFT")
    ) {
      const transitions = await this.getTransitions()
      this.setState({ transitions, isLoading: null })
      const hasDocuments = (transitions || []).filter(t => t.documentTypesList).length > 0
      if (hasDocuments) await this.getDocuments()

      if (this.props.statusList) {
        const prevAutoRefresh = getItem(this.props.statusList, prevEntity.status)?.autoRefresh
        const autoRefresh = getItem(this.props.statusList, entity.status)?.autoRefresh
        if (prevAutoRefresh !== autoRefresh) {
          let { intervalId } = this.state
          if (prevAutoRefresh) {
            if (intervalId) {
              clearInterval(intervalId)
              intervalId = null
            }
          } else if (autoRefresh) {
            intervalId = setInterval(this.handleAutoRefresh, 3000)
          }
          this.setState({ intervalId })
        }
      }
    }
  }

  handleAutoRefresh = async () => {
    const { serverRoute } = this.state
    const { entity = {}, include, onSetState } = this.props

    const { registration, readOnly } = entity
    if (!registration || !readOnly) return

    const { _updateDate } = (await axios.get(`${serverRoute}/${registration}?projection=_updateDate`)).data
    if (_updateDate > entity._updateDate) {
      const entity = (await axios.get(`${serverRoute}/${registration}?include=${include}`)).data
      onSetState(entity)
    }
  }

  getTransitions = async () => {
    const { serverRoute } = this.state
    const { entity, workflowName } = this.props
    if (!workflowName) return []
    try {
      if (!entity || (!entity._id && !entity.registration) || isOffline()) return null
      const query = entity.type ? "?type=" + entity.type : ""
      const transitions = (await axios.get(`${serverRoute}/${entity.registration || entity._id}/transitions${query}`)).data

      if (consoleService.showEntityWorkflowTransitions) {
        console.log(`---Workflow transitions (${entity.status}, ${getProfiles()}) :`, transitions)
      }

      for (const transition of transitions) {
        if (transition.documentTypesList) {
          getList(replaceVariables(transition.documentTypesList, entity), () => this.setState({ loaded: true }))
        }
      }

      return transitions
    } catch (error) {
      addOops(error)
    }
  }

  getDocuments = async (force, refreshEntity) => {
    const { serverRoute, loadingDocuments, fetched, transitions = [], loadingforeignDocuments } = this.state
    const { entity, foreignEntities = [], include } = this.props

    if (entity.documents && !force) {
      this.setState({ documents: entity.documents, fetched: true, loadingDocuments: false })
    } else {
      if (!loadingDocuments && (!fetched || force) && entity && serverRoute && entity.registration) {
        this.setState({ loadingDocuments: true }, async () => {
          try {
            const documents = (await axios.get(`${serverRoute}/${entity.registration}/documents`)).data
            this.setState({ documents, fetched: true, loadingDocuments: false })
            let patch
            if (refreshEntity) {
              const { data } = await axios.get(`${serverRoute}/${entity.registration || entity._id}${objectToSearchParam({ include })}`)
              patch = data
            }
            if (entity.documents && force) {
              patch = { ...(patch || {}), documents }
            }
            if (patch) this.props.onSetState(patch)
          } catch (error) {
            // do not display error
            // addOops(error)
            this.setState({ loadingDocuments: false })
          }
        })
      }
    }
    const foreignDocumentTransitions = transitions?.filter(it => it.entityName)

    if (foreignDocumentTransitions && !loadingforeignDocuments) {
      this.setState({ loadingforeignDocuments: true }, async () => {
        try {
          let foreignDocuments = []
          let promises = []

          for (let transition of foreignDocumentTransitions) {
            const { entityName } = transition
            const foreignEntity = foreignEntities[entityName]

            let foreignDocument = {
              id: entityName,
              entityName: foreignEntity.entityName || entityName,
              serverRoute: foreignEntity.serverRoute,
              entityRegistration: foreignEntity.entityRegistration,
            }
            if (foreignEntity.entityRegistration)
              promises.push(
                axios
                  .get(`${foreignDocument.serverRoute}/${foreignEntity.entityRegistration}/documents`)
                  .then(result => (foreignDocument.documents = result.data)),
              )
            foreignDocuments.push(foreignDocument)
          }
          if (!force) this.setState({ foreignDocuments }) // kind of hack to fix display glitch when closing documentModal

          await Promise.all(promises)
          this.setState({ foreignDocuments, loadingforeignDocuments: false })
        } catch {
          this.setState({ loadingforeignDocuments: false })
        }
      })
    }
  }

  handleDocumentsModalClose = () => {
    this.getDocuments(true)
    this.setState({ selectedTransition: null, foreignEntity: null, foreignServerRoute: null, foreignEntityName: null })
  }

  checkTransitionError = error => {
    const { entity, entityName, onSetState, handleSetState } = this.props
    const errors = error?.response?.data?.errors || []
    const fieldProps = error?.response?.data?.fieldProps
    const hasFieldProps = fieldProps && Object.keys(fieldProps).length > 0

    if (errors.length || hasFieldProps) {
      if (hasFieldProps) setEntityFieldProps(entity, fieldProps)

      handleSetState({ errors })
      onSetState(entity)
    } else {
      const errorMessage = error?.response?.data?.message || error.message || loc`Error saving` + ` ${entityName?.toLowerCase() || ""}`
      handleSetState({ errors: [errorMessage] })
      onSetState(entity)
      addOops(error)
    }
  }

  handleReport = async ({ reportName, reportUrl, reportOptions, payload, entity }) => {
    try {
      payload = payload || { entity }

      if (reportName) {
        reportName = replaceVariables(reportName, entity)
        reportUrl = `/api/report/runs/${reportName}`
      } else if (reportUrl) {
        reportUrl = replaceVariables(reportUrl, entity)
      }

      if (reportOptions?.displayMode === "selectOrUploadReport") {
        const { entityName } = this.props
        this.setState({
          modalDocument: {
            type: "personal",
            data: (await axios.get(`/api/report/reports?entityName=${entityName}`)).data,
            displayTitle: reportOptions.displayTitle ?? "Personal reports",
            showViewerToolbar: reportOptions.showViewerToolbar,
            displayWidth: reportOptions.displayWidth,
            payload,
            reportUrl,
          },
        })
        return
      }

      const response = await axios({
        method: reportUrl.startsWith("/api/report/excel") ? "get" : "post",
        url: reportUrl,
        responseType: "blob",
        data: payload,
      })

      if (reportName?.includes("saveType=") || reportUrl?.includes("saveType=")) this.getDocuments(true)

      // Try to find out the filename from the content disposition `filename` value
      const disposition = response.headers["content-disposition"]
      const matches = /"([^"]*)"/.exec(disposition)
      const filename = matches != null && matches[1] ? matches[1] : "file.docx"

      if (reportOptions?.displayMode === "modal") {
        this.setState({
          modalDocument: {
            type: "ephemeral",
            data: URL.createObjectURL(response.data),
            displayTitle: reportOptions.displayTitle ?? filename,
            showViewerToolbar: reportOptions.showViewerToolbar,
            // sm (modal-sm), lg (modal-lg), xl (modal-xl)
            // default is no value which is 600px, equivalent to md
            displayWidth: reportOptions.displayWidth,
          },
        })
        return
      }

      // The actual download
      const blob = new Blob([response.data])
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      addNotification(`Report printed`)
    } catch (error) {
      addOops(error)
    }
  }

  handlePrint = async transition => {
    let { entity, getPrintPayload, onSave } = this.props
    if (hasErrors(entity)) return

    let { reportName, reportUrl, reportOptions } = transition
    if (onSave && !entity.readOnly) {
      try {
        const _entity = await onSave()
        if (_entity) entity = _entity
      } catch (error) {
        return this.checkTransitionError(error)
      }
    }

    await this.handleReport({ reportName, reportUrl, reportOptions, payload: getPrintPayload?.(), entity })
  }

  handleWorkflowExecute = async (transition, foreignServerRoute, foreignEntity, documentTypesList, foreignEntityName) => {
    const { entity, handleMap } = this.props

    // TODO: Remove "documents" condition after checking/migrating documents transitions
    if (transition.code.startsWith("documents") || documentTypesList) {
      // open document modal
      this.setState({ selectedTransition: transition, foreignServerRoute, foreignEntity, documentTypesList, foreignEntityName })
      return
    }

    // TODO: Remove "print" condition after checking/migrating transitions
    if (transition.code.startsWith("print") || transition.reportUrl || transition.reportName) {
      // print entity
      this.setState({ isLoading: transition.code })
      await this.handlePrint(transition)
      this.setState({ isLoading: null })
      return
    }

    // Used in Finadev
    if (transition.code.startsWith("map")) {
      // show map with markers
      if (handleMap) handleMap(transition)
      return
    }

    if (transition.newTabUrl) return window.open(replaceVariables(transition.newTabUrl, entity), "_blank")

    if (transition.showCommentModal) {
      // Invoke the comment modal but note that it can "decide" by itself to not show up
      // if the reasons are computed by a script and none are returned.
      // This way the can be rendered optionally.
      this.setState({ isLoading: transition.code, showWorkflowCommentModal: { transition, entity } })
      return
    }

    await this.executeTransition(transition) // default: execute the transition
  }

  executeTransition = async (transition, reasons) => {
    const { serverRoute, isLoading } = this.state
    let { entity, history, include, onSetState, onSave, handleSetState } = this.props

    if (isLoading || (onSave && !entity.readOnly && checkErrors(entity, onSetState, { skipWorkflowErrors: true }))) return

    this.setState({ isLoading: transition.code })

    const prevStatus = entity.status
    let newStatus = prevStatus

    if (onSave && !entity.readOnly) {
      try {
        const _entity = await onSave()
        if (_entity) entity = _entity

        // Reset prev transition warnings and errors after saving successfully
        handleSetState({ warnings: [], errors: [] })
      } catch (error) {
        this.checkTransitionError(error)
        return this.setState({ isLoading: false })
      }
    }

    const { code } = transition

    try {
      const queryParams = objectToSearchParam({ include, code })
      const result = (await axios.post(`${serverRoute}/${entity.registration || entity._id}/transitions${queryParams}`, { code, reasons, entity }))
        .data

      let hasWarnings = false
      let hasErrors = false
      let hasFieldProps = false
      if (result.transitionResult) {
        const {
          redirectTo,
          newTabUrl,
          warnings: transitionWarnings = [],
          errors = [],
          fieldProps = {},
          closeWindow,
          download,
          softReload,
          reportUrl,
          reportName,
          reportOptions,
        } = result.transitionResult
        hasWarnings = transitionWarnings && transitionWarnings.length > 0
        hasErrors = errors && errors.length > 0
        hasFieldProps = Object.keys(fieldProps).length > 0

        if (redirectTo) {
          if (redirectTo.startsWith("http")) window.location.href = redirectTo
          else history.push(redirectTo)
        } else if (newTabUrl) {
          window.open(newTabUrl, "_blank")
          onSetState(result.entity)
        } else if (hasErrors || hasFieldProps || hasWarnings) {
          // displays all tabs if there are issues with the transition
          window.dispatchEvent(new CustomEvent(customEvents.navsCard.displayAllNavs))

          const { warnings, warningFieldProps } = getWarnings(transitionWarnings)
          if (Object.keys(warningFieldProps)?.length) setEntityFieldProps(result.entity, warningFieldProps)
          if (hasFieldProps) setEntityFieldProps(result.entity, fieldProps, { isWorkflowTransitionError: true })

          result.entity.isChanged = false
          handleSetState({ warnings, errors })
          if (!hasErrors && !hasFieldProps) newStatus = result.entity && result.entity.status
          onSetState(result.entity)
        } else if (download) {
          await downloadFileFromUrl(download.url, download.okMessage, download.koMessage, download.payload)
          this.getDocuments(true, true)
        } else {
          result.entity.isChanged = false
          newStatus = result.entity && result.entity.status
          onSetState(result.entity)

          if (reportUrl || reportName) await this.handleReport({ reportName, reportUrl, reportOptions, entity })
        }

        if (closeWindow) {
          if (window.opener) {
            window.close()
          } else {
            console.error("closeWindow was returned from workflow transition but current window wasn't opened by another one")
          }
        } else if (softReload) {
          window.dispatchEvent(new CustomEvent(customEvents.pagesLayout.reloadRoute))
        }
      } else {
        result.isChanged = false
        newStatus = result.status
        handleSetState({ warnings: [], errors: [] })
        onSetState(result)
      }

      if (!hasErrors && !hasFieldProps) addNotification(`Workflow transition executed${hasWarnings ? " with warnings" : ""}`)
    } catch (error) {
      addOops(error)
    }

    if (newStatus === prevStatus) {
      // status has not changed therefore didUpdate() will not call getTransition() so we need to reset the flag here
      this.setState({ isLoading: null })
    }
  }

  handleOpenWorkflowConfig = (event, workflowTransitionCode) => {
    event.stopPropagation()
    const { workflowName, entity, history } = this.props
    const debugUrl =
      `/scripts` +
      objectToSearchParam({
        name: workflowName,
        entityType: entity?.type,
        filter: workflowName,
        workflowTransitionCode,
      })
    if (event?.button === 1) {
      window.open(`${window.location.origin}${debugUrl}&all=1`, "_blank")
    } else {
      history.push(debugUrl)
    }
  }

  handleWorkflowCommentModalClose = reasons => {
    const { showWorkflowCommentModal } = this.state
    const { transition } = showWorkflowCommentModal || {}
    this.setState({ isLoading: false, showWorkflowCommentModal: null }, () => {
      if (reasons) this.executeTransition(transition, reasons)
    })
  }

  computeDisplayedDocument = (documentTypes, entityDocuments) => {
    const displayedDocumentType = new Set()
    let multipleDocument = 0
    entityDocuments.forEach(entityDocument => {
      for (const documentType of documentTypes) {
        if (
          documentType.value === entityDocument.type &&
          (entityDocument.groupIndex === undefined || entityDocument.groupIndex === null || documentType.groupIndex === entityDocument.groupIndex)
        ) {
          if (documentType.multiple) {
            multipleDocument += 1
          }
          displayedDocumentType.add({ type: entityDocument.type, multiple: documentType.multiple })
        }
      }
    })
    // removed mutiple documents counted twice
    if (multipleDocument > 0) {
      displayedDocumentType.forEach(doc => {
        if (doc.multiple) {
          multipleDocument = multipleDocument - 1
        }
      })
    }
    return displayedDocumentType.size + multipleDocument
  }

  getTransitionParams = transition => {
    const { documents = [], foreignDocuments = [] } = this.state
    const { entity } = this.props

    const { code, icon, label = labelFromName(code), documentTypesList, entityName, tooltip } = transition
    const documentGroupIndexes = new Set()
    const documentTypes = ((documentTypesList && getValues(replaceVariables(documentTypesList, entity))) || []).map(doc => {
      if (doc.group) {
        if (!documentGroupIndexes.has(doc.group)) {
          documentGroupIndexes.add(doc.group)
        }
        doc.groupIndex = documentGroupIndexes.size - 1
      }
      return doc
    })

    let { props } = transition
    props = props || { bsStyle: "primary", fill: true }
    const buttonProps = this.props[PROPS_ + code]

    let foreignEntity
    let foreignServerRoute
    let foreignEntityName
    let numberOfDisplayedDocuments // Number of documents which displayed in model in layout (must have the same type and the same groupIndex)
    let anyDocInError

    if (entityName) {
      const foreignDocument = foreignDocuments.find(it => it.id === entityName)
      if (!foreignDocument) return
      const foreignEntityDocuments = foreignDocument.documents || []
      numberOfDisplayedDocuments = this.computeDisplayedDocument(documentTypes, foreignEntityDocuments)
      anyDocInError = foreignEntityDocuments.find(doc => doc.error)
      foreignEntity = { registration: foreignDocument.entityRegistration }
      foreignServerRoute = foreignDocument?.serverRoute
      foreignEntityName = foreignDocument?.entityName || entityName
    } else {
      numberOfDisplayedDocuments = this.computeDisplayedDocument(documentTypes, documents)
      anyDocInError = documents.find(doc => doc.error)
    }

    return {
      code,
      buttonProps,
      foreignServerRoute,
      foreignEntity,
      foreignEntityName,
      documentTypesList,
      props,
      icon,
      label,
      numberOfDocuments: numberOfDisplayedDocuments,
      anyDocInError,
      tooltip,
    }
  }

  transitionToButton = (transition, index) => {
    const { isLoading } = this.state
    const { entity } = this.props

    const {
      code,
      buttonProps,
      foreignServerRoute,
      foreignEntity,
      foreignEntityName,
      documentTypesList,
      props,
      icon,
      label,
      numberOfDocuments,
      anyDocInError,
      tooltip,
    } = this.getTransitionParams(transition) || {}

    const locLabel = loc(buttonProps?.label || label)
    let transitionButton = (
      <CustomButton
        key={(code || "") + index}
        bsSize="small"
        disabled={isLoading || isOffline() || buttonProps?.disabled}
        {...props}
        onClick={
          !transition.clientRoute &&
          (() => this.handleWorkflowExecute(transition, foreignServerRoute, foreignEntity, documentTypesList, foreignEntityName))
        }
        linkTo={transition.clientRoute && replaceVariables(transition.clientRoute, entity)}
        className="inline-flex-center"
        aria-label={locLabel}
      >
        {isLoading === code && <i className="icn-circle-notch icn-spin icn-xs mr-5px" />}

        {isLoading !== code && icon && <i className={icon + " mr-5px"} />}

        {locLabel}

        {debug && (
          <DebugPopover
            debug
            fieldDebugBtn
            modelFieldPath={`${loc("Transition")} : ${code}`}
            onMouseDown={e => this.handleOpenWorkflowConfig(e, code)}
            onClick={e => this.handleOpenWorkflowConfig(e, code)}
          />
        )}

        {!isLoading && numberOfDocuments > 0 && <span className={"notification" + (anyDocInError ? "" : " bg-info")}>{numberOfDocuments}</span>}
      </CustomButton>
    )

    if (tooltip) {
      let tooltipText = tooltip
      let tooltipPlacement = "top"
      if (typeof tooltip === "object") {
        tooltipText = tooltip.text
        if (tooltip.placement) tooltipPlacement = tooltip.placement
      }
      transitionButton = (
        <OverlayTrigger placement={tooltipPlacement} trigger={["hover"]} overlay={<Tooltip id="edit">{loc(tooltipText)}</Tooltip>}>
          {transitionButton}
        </OverlayTrigger>
      )
    }

    return transitionButton
  }

  transitionGroupToDropdown = (groupLabel, transitions) => {
    const { isLoading } = this.state
    const dropdownLabel = loc(groupLabel)
    const title = (
      <>
        {dropdownLabel}
        {transitions?.find(transition => transition.code === isLoading) && <i className="icn-circle-notch icn-spin icn-xs ml-5px" />}
      </>
    )

    const firstTransition = transitions?.[0]
    const { bsStyle = "btn-primary", fill, pullRight } = firstTransition?.groupProps || firstTransition?.props || {}
    const dropdownInnerBsStyle = bsStyle + (fill ? " btn-fill" : "")
    const firstTransitionCode = transitions[0].code

    return (
      <Dropdown
        id={groupLabel}
        key={groupLabel}
        disabled={!!isLoading || isOffline()}
        bsSize="sm"
        bsStyle={pullRight ? " pull-right" : ""}
        onClick={event => {
          const { target } = event
          target.focus()
          target.nextSibling?.click()
        }}
      >
        {debug && (
          <DebugPopover
            debug
            fieldDebugBtn
            modelFieldPath={`${loc("Transition")} : ${firstTransitionCode}`}
            onMouseDown={e => this.handleOpenWorkflowConfig(e, firstTransitionCode)}
            onClick={e => this.handleOpenWorkflowConfig(e, firstTransitionCode)}
          />
        )}
        <CustomButton
          bsStyle={dropdownInnerBsStyle}
          disabled={isLoading || isOffline()}
          className="inline-flex align-items-center overflow-hidden"
          tabIndex="-1"
        >
          {title}
        </CustomButton>
        <Dropdown.Toggle bsStyle={dropdownInnerBsStyle} onKeyDown={onDropdownToggleKeyDown} aria-label={dropdownLabel} />
        <Dropdown.Menu className="br-theme overflow-hidden border-0">
          {transitions?.map((transition, key) => {
            const { code, buttonProps, foreignServerRoute, foreignEntity, foreignEntityName, documentTypesList, icon, label, props } =
              this.getTransitionParams(transition)
            const { bsStyle, fill } = props || {}

            return (
              <CustomButton
                bsStyle={bsStyle}
                fill={fill}
                bsSize="sm"
                className="btn-simple w-100 text-left br-0 m-0 inline-flex align-items-center outline-accessible-inset"
                key={key}
                eventKey={key}
                disabled={buttonProps?.disabled || isLoading === code}
                onKeyDown={onDropdownMenuButtonKeyDown}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (buttonProps?.disabled) return
                  this.handleWorkflowExecute(transition, foreignServerRoute, foreignEntity, documentTypesList, foreignEntityName)
                }}
              >
                {icon && (isLoading === code ? <i className="icn-circle-notch icn-spin icn-xs mr-5px" /> : <i className={icon + " mr-5px"} />)}
                {loc(buttonProps?.label || label)}
              </CustomButton>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  transformTransitionsToButtons = transitions => {
    const allowedPositions = ["top", "bottom"]
    const transitionButtons = {}
    const btnGroups = {}

    const getTransitionPosition = transition => transition.props?.position?.toLowerCase() || allowedPositions[0]

    const isTransitionHidden = (transition, tPosition) => {
      return transition.hidden || this.props[PROPS_ + transition.code]?.hidden || !allowedPositions.includes(tPosition)
    }

    for (let i = 0; i < transitions?.length; i++) {
      const transition = transitions[i]
      const tPosition = getTransitionPosition(transition)
      if (isTransitionHidden(transition, tPosition) || !transition.groupLabel) continue

      if (!btnGroups[tPosition]) btnGroups[tPosition] = {}
      if (!btnGroups[tPosition][transition.groupLabel]) btnGroups[tPosition][transition.groupLabel] = []
      btnGroups[tPosition][transition.groupLabel].push(transition)
    }

    for (let i = 0; i < transitions?.length; i++) {
      const transition = transitions[i]
      const tPosition = getTransitionPosition(transition)
      if (isTransitionHidden(transition, tPosition)) continue

      if (!transitionButtons[tPosition]) transitionButtons[tPosition] = []

      if (transition.groupLabel) {
        if (!btnGroups[tPosition][transition.groupLabel].used) {
          btnGroups[tPosition][transition.groupLabel].used = true
          transitionButtons[tPosition].push(this.transitionGroupToDropdown(transition.groupLabel, btnGroups[tPosition][transition.groupLabel]))
        }
      } else {
        transitionButtons[tPosition].push(this.transitionToButton(transition, i))
      }
    }

    return transitionButtons
  }

  handlePersonalReportUpload = async event => {
    const { entityName } = this.props
    const files = Array.from(event.target.files)
    if (!files || files.length === 0) return
    const file = files[0]
    if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") return
    let formData = new FormData()
    formData.append("file", files[0])
    formData.append("extension", "docx")
    formData.append("name", file.name)
    formData.append("entityName", entityName)
    event.target.value = null
    this.setState({ uploadLoading: true })
    try {
      const { modalDocument } = this.state
      modalDocument.data ||= []
      const existingReportIndex = modalDocument.data.findIndex(it => it.name === file.name)
      if (existingReportIndex !== -1) {
        await axios.delete(`/api/report/reports/${modalDocument.data[existingReportIndex]._id}`)
        modalDocument.data.splice(existingReportIndex, 1)
      }
      const { data: report } = await axios.post("/api/report/reports", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      modalDocument.data.push(report)
      this.setState({ modalDocument })
      addNotification(`File uploaded`)
      this.handlePersonalReportRun(report)
    } catch (error) {
      addOops(error)
    }
    this.setState({ uploadLoading: false })
  }

  handlePersonalReportDownload = async id => {
    const { modalDocument } = this.state
    const report = modalDocument.data.find(it => it._id === id)
    report.downloadLoading = true
    this.setState({ modalDocument })
    try {
      const response = await axios.get(`/api/report/reports/${id}/content`, { responseType: "blob" })

      const disposition = response.headers["content-disposition"]
      const matches = /"([^"]*)"/.exec(disposition)
      const filename = matches != null && matches[1] ? matches[1] : "file.docx"

      const blob = new Blob([response.data])
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      addOops(error)
    } finally {
      report.downloadLoading = false
      this.setState({ modalDocument })
    }
  }

  handlePersonalReportDelete = async (id, index) => {
    const { modalDocument } = this.state
    const report = modalDocument.data.find(it => it._id === id)
    report.deleteLoading = true
    this.setState({ modalDocument })
    try {
      await axios.delete(`/api/report/reports/${id}`)
      modalDocument.data.splice(index, 1)
      this.setState({ modalDocument })
    } catch (error) {
      addOops(error)
      report.deleteLoading = false
      this.setState({ modalDocument })
    }
  }

  handlePersonalReportRun = async report => {
    const { modalDocument } = this.state
    const { reportUrl, payload } = modalDocument

    const _report = modalDocument.data.find(it => it._id === report._id)
    _report.runLoading = true
    this.setState({ modalDocument })
    await this.handleReport({ reportUrl: reportUrl.replace("*", report.name), payload })
    _report.runLoading = false
    this.setState({ modalDocument })
  }

  render() {
    const {
      transitions = [],
      showWorkflowCommentModal,
      serverRoute,
      foreignServerRoute,
      foreignEntity,
      foreignEntityName,
      documentTypesList,
      selectedTransition,
      modalDocument,
      uploadLoading,
    } = this.state

    const { entity, roles = [], entityName, saveButton } = this.props
    const entityPersonRoles = (entity.persons || []).map(p => p.role)
    const transitionButtons = this.transformTransitionsToButtons(transitions)
    const hasBottomTransitions = transitionButtons.bottom?.length > 0
    const hasTransitions = transitions?.length

    if (!saveButton && !transitionButtons.top && !hasBottomTransitions && !debug && !hasTransitions && !selectedTransition) {
      return null
    }

    return (
      <Row>
        <Col xs={12} className="entity-workflow-buttons">
          <div className="transitions-top">
            {saveButton}
            {transitionButtons.top}
            {/*
            Because all of its children are floating the div transitions-top does not have height so small margin glitches can appear.
            We add this div to force it to have height.
            Read https://www.quirksmode.org/css/clearing.html
            We don't use the new solution from this article because it hides part of the transitions notifications
            (the round containing the number of documents or errors).
          */}
            {hasBottomTransitions && <div className="float-clear"></div>}
          </div>

          {hasBottomTransitions && (
            <>
              <div className="divider-light mt-5px mb-5px"></div>
              <div className="transitions-bottom">{transitionButtons.bottom}</div>
            </>
          )}

          {debug && !hasTransitions && (
            <CustomButton bsSize="small" wd bsStyle="primary">
              {loc("Edit workflow")}
              <span className="notification" onClick={this.handleOpenWorkflowConfig}>
                ?
              </span>
            </CustomButton>
          )}

          {selectedTransition && (
            <DocumentsModal
              roles={roles.filter(role => {
                const index = entityPersonRoles.findIndex(r => r === role)
                if (index === -1) return false
                else {
                  entityPersonRoles[index] = undefined
                  return true
                }
              })}
              entityName={foreignEntityName ? foreignEntityName : entityName}
              entity={foreignEntity && foreignServerRoute ? foreignEntity : entity}
              serverRoute={foreignEntity && foreignServerRoute ? foreignServerRoute : serverRoute}
              documentTypesList={documentTypesList}
              entityRegistration={(foreignEntity || entity).registration}
              onClose={this.handleDocumentsModalClose}
              title={selectedTransition.label}
              canUpload={this.props[PROPS_ + selectedTransition?.code]?.canUpload}
            />
          )}

          {showWorkflowCommentModal && (
            <WorkflowCommentModal
              entity={showWorkflowCommentModal.entity}
              onClose={this.handleWorkflowCommentModalClose}
              transition={showWorkflowCommentModal.transition}
            />
          )}

          {modalDocument && (
            <Modal
              show={true}
              onHide={() => {
                URL.revokeObjectURL(modalDocument.data + (modalDocument.showViewerToolbar ? "" : "#toolbar=0"))
                this.setState({ modalDocument: undefined })
              }}
              backdrop="static"
              className="workflow-document-modal"
              bsSize={modalDocument.displayWidth || ""}
            >
              <Modal.Header closeButton>
                <Modal.Title>{loc(modalDocument.displayTitle)}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {modalDocument.type === "ephemeral" && (
                  <embed width="100%" src={modalDocument.data + (modalDocument.showViewerToolbar ? "" : "#toolbar=0")} />
                )}
                {modalDocument.type === "personal" && (
                  <>
                    {modalDocument.data.map((it, index) => (
                      <Row key={index} className="document-wrapper">
                        <div className="document-identification">{it.name}</div>
                        <div className="document-controls">
                          <CustomButton
                            disabled={it.deleteLoading}
                            bsStyle="danger"
                            className="flex-center ml-2"
                            bsSize="xs"
                            simple
                            onClick={() => this.handlePersonalReportDelete(it._id, index)}
                          >
                            <i className={`icn-${it.deleteLoading ? "circle-notch icn-spin" : "xmark"} icn-xs`} />
                          </CustomButton>
                          <CustomButton
                            disabled={it.downloadLoading}
                            bsStyle="info"
                            bsSize="xs"
                            className="flex-center ml-2"
                            simple
                            onClick={() => this.handlePersonalReportDownload(it._id)}
                          >
                            <i className={`icn-${it.downloadLoading ? "circle-notch icn-spin" : "download"} icn-xs`} />
                          </CustomButton>
                          <CustomButton
                            disabled={it.runLoading}
                            bsStyle="success"
                            bsSize="xs"
                            className="flex-center ml-2"
                            simple
                            onClick={() => this.handlePersonalReportRun(it)}
                          >
                            <i className={`icn-${it.runLoading ? "circle-notch icn-spin" : "play"} icn-xs`} />
                          </CustomButton>
                        </div>
                      </Row>
                    ))}
                    <Row className="d-block">
                      <CustomButton
                        bsStyle="primary"
                        bsSize="small"
                        pullRight
                        className="mt-theme"
                        loading={uploadLoading}
                        image="icn-cloud-upload icn-sm mr-5px"
                        label={loc`Upload DOCX report`}
                        onClick={() => this["upload_"].click()}
                      />
                      <input
                        type="file"
                        id="single"
                        ref={ref => (this["upload_"] = ref)}
                        onChange={event => this.handlePersonalReportUpload(event)}
                        style={{ display: "none" }}
                      />
                    </Row>
                  </>
                )}
              </Modal.Body>
            </Modal>
          )}
        </Col>
      </Row>
    )
  }
}

function getWarnings(transitionWarnings) {
  let warnings = []
  const warningFieldProps = {}

  if (transitionWarnings?.length) {
    warnings = transitionWarnings.filter(w => !w.modelFieldPath)
    const fields = transitionWarnings.filter(w => w.modelFieldPath)
    if (fields.length) {
      fields.forEach(f => {
        warningFieldProps[f.modelFieldPath] = { warningFormat: f.value }
      })
    }
  }
  return { warnings, warningFieldProps }
}

export default withRouter(EntityWorkflowButtons)
